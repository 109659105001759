export default {
  "findActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдите нужное занятие"])},
  "bookActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забронируйте из множества занятий."])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать в ваш портал бронирования"])},
  "userNameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя или Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
  "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильное имя пользователя или пароль"])},
  "createAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать администратора"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
  "settingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
  "selectLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите язык"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить пароль"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий пароль"])},
  "iCommit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я Подтверждаю"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
  "commitmentConfirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение обязательств"])},
  "commitmentMessagePerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмёте 'Я Подтверждаю,' стоимость занятия будет добавлена в ваш ежемесячный счёт в течение следующих 3 месяцев."])},
  "commitmentMessagePerYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмёте 'Я Подтверждаю,' стоимость занятия будет добавлена в ваш ежегодный счёт."])},
  "commitmentMessageQuarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмёте 'Я Подтверждаю,' стоимость занятия будет разделена на квартальные платежи."])},
  "commitmentMessageBiAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмёте 'Я Подтверждаю,' стоимость занятия будет разделена на полугодовые платежи."])},
  "commitmentMessageTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмёте 'Я Подтверждаю,' полная стоимость занятия будет снята немедленно."])},
  "passwordTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот пароль будет использоваться пользователем для входа в систему. Убедитесь, что он надежен. Администраторы могут устанавливать и изменять пароли, но пользователи могут изменять их после входа в систему. Администраторы не могут просматривать пароли пользователей."])},
  "subRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль пользователя"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
  "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите новый пароль"])},
  "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напоминания"])},
  "selectTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите временные слоты"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно"])},
  "remindersManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление напоминаниями"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить пароль"])},
  "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новые пароли не совпадают."])},
  "publishUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовать пользователя"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить пользователя"])},
  "saveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить черновик"])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения"])},
  "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль успешно обновлен."])},
  "passwordUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обновить пароль."])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежные данные"])},
  "editPaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать платежные данные"])},
  "updatePaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить платежные данные"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Английский"])},
  "noSlotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет доступных слотов"])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немецкий"])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напоминание"])},
  "latvian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Латышский"])},
  "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
  "roleManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ролями"])},
  "allActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все занятия"])},
  "userManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление пользователями"])},
  "locationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление местоположением"])},
  "cohortManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление когортой"])},
  "calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарный вид"])},
  "whatsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что нового"])},
  "extracurricularActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внеклассные мероприятия"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
  "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Навигация"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
  "filterActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтровать занятия"])},
  "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать местоположение"])},
  "selectTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать учителя"])},
  "selectCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать когорту"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "goToDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к деталям"])},
  "monthView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месячный вид"])},
  "weekView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недельный вид"])},
  "dayView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дневной вид"])},
  "activityApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центр утверждения бронирования"])},
  "holidayManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление праздниками"])},
  "noPendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В данный момент нет ожидающих бронирований."])},
  "confirmBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить бронирование"])},
  "confirmBookingMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы хотите забронировать ", _interpolate(_named("activityName")), " для ", _interpolate(_named("childName")), "?"])},
  "confirmBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить бронирование"])},
  "doYouWantToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы хотите забронировать"])},
  "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["для"])},
  "paymentDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Мы свяжемся с вами по поводу деталей оплаты. Стоимость занятия ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), "(", _interpolate(_named("pricingType")), "). После оплаты мы сможем подтвердить бронирование."])},
  "paymentDetailsSlotMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цена за сеанс составляет ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), ". Платеж будет добавлен к вашему ежемесячному счету."])},
  "perMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в месяц"])},
  "perYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в год"])},
  "recurringDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторяющееся бронирование"])},
  "recurringDialogMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы хотите забронировать это время на каждую неделю?"])},
  "commitmentMessageSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмете 'Я подтверждаю', стоимость сеанса будет добавлена к вашему ежемесячному счету."])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
  "selectAllChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать всех детей"])},
  "quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ежеквартально"])},
  "biAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["раз в полгода"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["всего"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
  "currentActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие занятия"])},
  "myProfileData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои данные профиля"])},
  "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать профиль"])},
  "upcomingActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предстоящие занятия"])},
  "profileUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль успешно обновлен"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотреть"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
  "resetPasswordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить пароль"])},
  "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите адрес электронной почты"])},
  "sendResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить ссылку для сброса"])},
  "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуется адрес электронной почты"])},
  "resetLinkSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка для сброса отправлена! Проверьте свою почту."])},
  "resetLinkFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отправить ссылку для сброса. Попробуйте еще раз."])},
  "emailAndPasswordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуются email и пароль"])},
  "allFieldsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для создания администратора необходимы все поля"])},
  "slotAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот слот уже забронирован. Пожалуйста, выберите другое доступное время."])},
  "activityBookedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Мероприятие '", _interpolate(_named("activity")), "' успешно забронировано."])},
  "filterByChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр по ребенку"])},
  "bookingConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование подтверждено"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что-то пошло не так. Пожалуйста, попробуйте позже."])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка..."])},
  "secureAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обеспечьте безопасность своего аккаунта"])},
  "chooseNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите новый пароль, чтобы восстановить доступ"])},
  "resetYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить пароль"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить пароль"])},
  "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль обязателен"])},
  "passwordTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен содержать не менее 8 символов"])},
  "passwordNumberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен содержать хотя бы одну цифру"])},
  "passwordTooWeak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль слишком слабый"])},
  "passwordResetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш пароль успешно сброшен"])},
  "passwordResetFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сбросить пароль"])},
  "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к входу"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
  "activityBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Занятие успешно забронировано, ожидает подтверждения."])},
  "confirmCancellationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы хотите отменить бронирование для ", _interpolate(_named("activityName")), " для ", _interpolate(_named("childName")), "?"])},
  "confirmCancellationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить отмену"])},
  "confirmAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить действие"])},
  "confirmActionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите продолжить это действие?"])},
  "bookingCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование отменено."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
  "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Временные интервалы"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники"])},
  "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когорты"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строк на странице"])}
}